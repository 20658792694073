$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('component-videos-home', {
    template: `
        <element>
            <div class="videos-home">
                <div class="video" v-for="item in videosHome">
                    <a :href="getNoticiaUrl(item)" :title="isEnglish ? (item.TitleEN ? item.TitleEN : item.Title) : item.Title" class="link-video" target="_blank">
                        <img v-if="item.LinkImagem4 || item.LinkImagem" :src="isEnglish ? (item.LinkImagem4 ? item.LinkImagem4 : item.LinkImagem) : item.LinkImagem" :alt="isEnglish ? (item.TitleEN ? item.TitleEN : item.Title) : item.Title" class="img-fluid" />
                    </a>
                    <div class="container text-video">
                        <a :href="getNoticiaUrl(item)" :title="isEnglish ? (item.TitleEN ? item.TitleEN : item.Title) : item.Title" target="_blank">
                            <h4 class="text-uppercase text-primary font-weight-bold mt-3">
                                {{ isEnglish ? (item.TitleEN ? item.TitleEN : item.Title) : item.Title }}
                                <span class="date text-secondary text-uppercase d-sm-none d-inline mt-3">{{ getDataItem(item) }}</span>
                            </h4>
                        </a>
                        <span class="date d-none d-sm-block mt-3 mb-3 text-uppercase">{{ getDataItem(item) }}</span>
                        <span class="d-none d-sm-block border-space mb-2"></span>
                        <p class="d-none d-sm-block" v-html="truncateString(isEnglish ? (item.ResumoEN ? item.ResumoEN : item.Resumo) : item.Resumo, 350)"></p>
                    </div>
                </div>
            </div>
        </element>
    `,
    mixins: [pnpMixin, translateMixin],
    created: function () {
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
            .then(this.applyEvents)
    },
    methods: {
        getData: function () {
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            var sociedade = SociedadeValue;
            var filtro;

            if (sociedade == "LAW") {
                filtro = "(Destaque eq 'Não') and (DestaqueHome eq 'Não')"
            } else {
                filtro = "(DestaqueLateralPI eq 'Não') and (DestaqueMaiorPI eq 'Não')"
            }

            return web.getList(this.listUrl).items
                .filter(`${filtro} and ('${sociedade}' eq AuxSociedade/Title) and (AuxIdioma/lang eq ${CurrentLanguageDecimal}) and AuxIdioma/Title ne 'fr-FR' and (DataPublicacao le '${moment().format('YYYY-MM-DD')}T00:00:00-03:00') and (Editoria/Title eq 'Vídeos' or Editoria/Title eq 'Podcasts' or Editoria/Title eq 'Publicações')`)
                .select('*, AuxSociedade/Title, AuxIdioma/Title, Editoria/Title, AreaPrincipal/SeoURL, AreaPrincipal/SeoURLen')
                .expand('AuxSociedade, AuxIdioma, Editoria, AreaPrincipal')
                // .orderBy('Ordem',true)
                .orderBy('Data', false)
                .top(2)
                .get()
        },
        applyGetData: function (data) {
            this.videosHome = data;
        },
        getUrlVideo(url) {
            if (url.match(/spotify/i)) {
                var idPodcast = url.split(`/episode/`)[1];
                var prefixSpotify = `https://open.spotify.com/embed-podcast/episode/`
                return `${prefixSpotify}${idPodcast}`
            } else {
                var idYoutube;
                if ((url.includes('youtu.be')) && url.split('youtu.be')[1]) {
                    idYoutube = url.split('youtu.be/')[1];
                } else {
                    idYoutube = url.split(`/watch?v=`)[1];
                }
                if (idYoutube.includes('&'))
                    idYoutube = idYoutube.split('&')[0]
                var prefixYT = `https://www.youtube.com/embed/`;
                return `${prefixYT}${idYoutube}`;
            }
        },
        applyEvents: function () {

            //videos home - slick slider
            $('.videos-home').slick({
                arrows: true,
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            arrows: false
                        }
                    }
                ]
            });
        },
        getDataItem(item) {
            return moment(item.Data).format('DD . MMM . YYYY')
        },
        getNoticiaUrl(item) {
              var area = (!this.isEnglish && (item.AreaPrincipal || {}).SeoURL ? (item.AreaPrincipal || {}).SeoURL : (item.AreaPrincipal || {}).SeoURLen) || 'institucional';
            const idioma = this.isEnglish ? '/en-US' : '';

            if (this.isEnglish && item.URLPublicacaoEN) {
                return item.URLPublicacaoEN;
            } else if (item.URLPublicacao) {
                return item.URLPublicacao;
            }

            if (_spPageContextInfo.webAbsoluteUrl.includes('/pi')) {
                return `${(_spPageContextInfo.webAbsoluteUrl).split('/pi')[0]}${idioma}/pi/conteudo/${area}/${this.isEnglish ? (item.SeoURLen ? item.SeoURLen : item.SeoURL) : item.SeoURL}`;
            } else {
                return `${_spPageContextInfo.webAbsoluteUrl}${idioma}/conteudo/${area}/${this.isEnglish ? (item.SeoURLen ? item.SeoURLen : item.SeoURL) : item.SeoURL}`;
            }
        },
        threatError: function (msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        },
        /*coverVideo(item){
            if(item.LinkVideo){
                var idVideo = item.LinkVideo.split('watch?v=')[1];
                return `http://i1.ytimg.com/vi/${idVideo}/hqdefault.jpg`;
            }else{
                return false;
            }
        }*/
    },
    data: function () {
        var listName = 'Noticias'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/' + listName + '',
            videosHome: []
        }
    }
})
Vue.component('busca-home', {
    mixins: [translateMixin],
    template: `
    <element>
        <input type="text" :placeholder="isEnglish ? 'What are you looking for?' : 'O que você procura?'" @keypress.enter.prevent="redirectPageSearch()" v-model="keyWord" />
        <button type="button" @click="redirectPageSearch()"><i class="lupa-search"></i></button>
        <a href="" :title="isEnglish ? 'What are you looking for?' : 'O que você procura?'"></a>
    </element>
    `,
    methods: {
        redirectPageSearch(){
            if(this.keyWord){
                return window.location.href = `${_spPageContextInfo.webServerRelativeUrl}/paginas/busca.aspx#keyword=${this.keyWord}`.replace('//paginas', '/paginas')
            }
        }
    },
    data() {
        return {
           keyWord: null
        }
    },
})

var app = new Vue({
    el: '#busca-home',
    template: '<busca-home/>'
})
$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});
Vue.component('component-responsabilidade-social',{
    template:`
        <element>
            <!-- slide mobile   -->
            <div class="slider-responsabilidades d-sm-none">
                <div class="resp text-center pt-4" v-for="item in responsabilidadeSocial">
                    <a :href="formatUrl(item.Ancora)" class="polygon saiba-mais"><i class="fas fa-plus"></i></a>
                    <a :href="formatUrl(item.Ancora)">
                        <img :src="item.LinkImagem" :alt="!isEnglish ? item.Title : item.TitleEn" class="mx-auto d-block" />
                    </a>
                    <div class="text-resp pl-5 pr-5 mt-2 mb-2">
                        <p>{{ truncateString(!isEnglish ? item.Descricao : (item.DescricaoEn || item.Descricao), 205) }}</p>
                    </div>
                </div>
            </div>

            <!-- responsabilidade desktop -->
            <div class="d-none d-sm-block mt-4">
                <div class="row">
                    <div class="resp text-center pt-4" v-for="item in responsabilidadeSocial" :class="responsabilidadeSocial.length < 6 ? 'col-sm-6' : 'col-sm-4'">
                        <div class="logo">
                            <a :href="formatUrl(item.Ancora)"><img :src="item.LinkImagem" :alt="!isEnglish ? item.Title : item.TitleEn" class="mx-auto d-block" /></a>
                        </div>
                        <div class="text-resp pl-5 pr-5 mt-2 mb-2">
                            <div v-html="truncateString(!isEnglish ? item.Descricao : (item.DescricaoEn || item.Descricao), 160)"></div>
                            <a :href="formatUrl(item.Ancora)" class="polygon saiba-mais">
                                <i class="fas fa-plus d-block d-sm-none"></i>
                                <span class="d-none d-sm-inline text-uppercase mx-auto">{{ !isEnglish ? 'Saiba Mais' : 'Read More' }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </element>
    `,
    mixins: [pnpMixin, translateMixin],
    created: function(){
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this,'erro ao obter informações da lista '+this.listName+''))
            .then(this.applyEvents)
    },
    methods: {
        getData: function(){
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                .filter(`LocalExibicao eq 'Home' or LocalExibicao eq 'Ambos' and LocalExibicao ne 'Oculto'`)
                .select('*')
                //.expand()
                .orderBy('Ordem',true)
                //.top()
                .get()
        },
        applyGetData: function(data){
            this.responsabilidadeSocial = data;
        },
        applyEvents: function(){
            // slider responsabilidades
            Vue.nextTick(function(){
                $('.slider-responsabilidades').slick({
                    dots: false,
                    arrows: false,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                                dots: true,
                                autoplay: true,
                                autoplaySpeed: 2000
                            }
                        }
                    ]
                })
            })
        },
        threatError: function(msg,data){
            console.error('error - ' + msg + ' - ' + data);
        }
    },
    data: function() {
        var listName = 'ResponsabilidadeSocial'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/'+ listName+'',
            responsabilidadeSocial: []
        }
    }
})
$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('component-noticias-publicacoes', {
    template: `
    <element>
        <!--<categorias-noticias @change="onClickCategoria"></categorias-noticias>-->
        
        <div class="noticias-midia container content-full polygon after-left">
            <div class="row">
                <!-- noticia destaque -->
                <div v-if="noticiaDestaque" class="col-12 col-sm-6">
                    <div class="noticia-destaque">
                        <div class="border-top border-tertiary img-destaque" :class="!noticiaDestaque.LinkImagem ? 'mt-4' : ''">
                            <a v-if="noticiaDestaque.LinkImagem" :href="getNoticiaUrl(noticiaDestaque)" :title="!isEnglish ? noticiaDestaque.Title : noticiaDestaque.TitleEN" class="d-block clearfix">
                                <img :src="noticiaDestaque.LinkImagem + '?RenditionID=5'" :alt="!isEnglish ? noticiaDestaque.Title : noticiaDestaque.TitleEN" class="img-fluid w-100"/>
                            </a>
                            <a v-else :href="getNoticiaUrl(noticiaDestaque)" :title="!isEnglish ? noticiaDestaque.Title : noticiaDestaque.TitleEN" class="destaque-sem-imagem">
                                
                            </a>
                            <span class="cat-noticia d-block d-sm-none" v-if="noticiaDestaque.AuxCategoria.results.length">{{ noticiaDestaque.AuxCategoria.results[0].Title }}</span>
                        </div>
                        <div class="text-noticia-destaque p-3 bg-light">
                            <a :href="getNoticiaUrl(noticiaDestaque)" :title="!isEnglish ? noticiaDestaque.Title : noticiaDestaque.TitleEN" class="d-block clearfix">
                                <h4 class="text-uppercase text-primary font-weight-bold">{{ !isEnglish ? noticiaDestaque.Title : (noticiaDestaque.TitleEN || noticiaDestaque.Title) }}</h4>
                            </a>
                            <h5 class="text-uppercase">{{ getCustomData(noticiaDestaque.Data) }}</h5>
                            <hr class="mt-3 mb-3"/>
                            <p class="d-none d-sm-block">{{ !isEnglish ? noticiaDestaque.Resumo : noticiaDestaque.ResumoEN }}
                                <a :href="getNoticiaUrl(noticiaDestaque)" title="" class="leia-mais text-primary text-italic">{{ !isEnglish ? 'Leia Mais' : 'Click here to read more' }}</a>
                            </p>
                        </div>
                    </div>
                </div>
                <!--    demais noticias    -->

                <div class="col-12 col-sm-6">
                    <div class="noticias-slider bg-light pb-5">
                        <div v-if="noticiasPublicacoes.length" class="container">
                            <div id="slider-midia">
                                <div class="bg-white" v-for="item in noticiasPublicacoes">
                                    <div class="noticia row">
                                        <div class="col-4 col-sm-4">
                                            <a :href="getNoticiaUrl(item)" :title="!isEnglish ? item.Title : item.TitleEN" class="link-foto">
                                                <img v-if="item.LinkImagem || item.LinkImagem4" :src="(item.LinkImagem || item.LinkImagem4) + '?RenditionID=9'" alt="Maurício Faro participa do ciclo de palestras promovido pela PGE-RJ" class="w-100"/>
                                               
                                                <span class="cat-noticia bg-primary text-white text-uppercase d-block d-sm-none">{{ getCustomData(item.Data) }}</span>
                                            </a>
                                        </div>
                                        
                                        <div class="col-8 col-sm-8 pt-2">
                                            <a :href="getNoticiaUrl(item)" :title="!isEnglish ? item.Title : item.TitleEN" class="link-title">
                                                <span class="data text-uppercase text-primary">
                                                    <span class="d-none">{{ getCustomData(item.Data) }}</span>
                                                    <span class="d-none d-sm-block" v-html="getCustomData2(item.Data)"></span>
                                                </span>
                                                <h4 class="mt-sm-2">{{ truncateString(!isEnglish ? item.Title : (item.TitleEN || item.Title), 120) }}</h4>
                                                <p class="d-none d-sm-block" v-html="truncateString(item.Descricao, 50)"><span class="text-italic text-primary">{{ !isEnglish ? 'Leia mais' : 'Read More' }}</span></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <a href="/conteudo/busca#" title="" class="d-block p-3 text-center text-uppercase border border-primary text-primary mt-4 mb-4 font-weight-bold ver-todos">
                                {{ !isEnglish ? dadosBtn.TitleBotao : dadosBtn.TitleBotaoEn }}
                            </a>
                        </div>
                        <div v-else class="container p-0">
                            <h5>{{ !isEnglish ? 'Não existem publicações nesta categoria.' : 'No news in this category' }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </element>
    `,
    created: function () {
        this.loadResults()
    },
    mixins: [pnpMixin, translateMixin],
    props: {
        dadosBtn: { required: false }
    },
    methods: {
        loadResults(categoriaID) {
            this.getNoticiaDestaque(categoriaID)
                .then(this.getData.bind(this, categoriaID))
                .then(this.applyGetData, this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
                .then(this.applyEvents)
        },
        getNoticiaDestaque(categoriaID) {
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            var sociedade = SociedadeValue;
            var filtro;

            if (sociedade == "LAW") {
                filtro = "(Destaque eq 'Não') and (DestaqueHome eq 'Sim')"
            } else {
                filtro = "(DestaqueLateralPI eq 'Não') and (DestaqueMaiorPI eq 'Sim')"
            }


            return web.getList(this.listUrl).items
                .filter(` ${filtro} and AuxSociedade/Title eq '${sociedade}' and AuxIdioma/lang eq ${CurrentLanguageDecimal} and AuxIdioma/Title ne 'fr-FR' and (DataPublicacao le '${moment().format('YYYY-MM-DD')}T00:00:00-03:00')`)
                .select('*, AuxCategoria/Id, AuxCategoria/Title, AuxCategoria/TitleEN, AuxSociedade/Title, AreaPrincipal/SeoURLen, AreaPrincipal/SeoURL')
                .expand('AuxCategoria, AuxSociedade, AuxSociedade, AreaPrincipal')
                .orderBy('Data', false)
                .top(1)
                .get().then((destaque) => {
                    this.$set(this, 'noticiaDestaque', destaque[0]);
                    destaque = destaque[0] || {}
                    this.destaqueId = destaque.Id || 0;
                })
        },
        getData: function (categoriaID) {
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            var sociedade = SociedadeValue;
            var filtro;

            if (sociedade == "LAW") {
                filtro = "(Destaque eq 'Sim') and (DestaqueHome eq 'Não')"
            } else {
                filtro = "(DestaqueLateralPI eq 'Sim') and (DestaqueMaiorPI eq 'Não')"
            }


            return web.getList(this.listUrl).items
                .filter(`${filtro} and ('${sociedade}' eq AuxSociedade/Title) and (AuxIdioma/lang eq ${CurrentLanguageDecimal}) and AuxIdioma/Title ne 'fr-FR' and (Id ne ${this.destaqueId}) and(DataPublicacao le '${moment().format('YYYY-MM-DD')}T00:00:00-03:00')`)
                //.filter(`Destaque eq 'Sim' and '${sociedade}' eq AuxSociedade/Title and AuxIdioma/lang eq ${CurrentLanguageDecimal}`)
                .select('*, AuxCategoria/Id, AuxCategoria/Title, AuxCategoria/TitleEN, AuxIdioma/lang, AuxSociedade/Title, AreaPrincipal/SeoURLen, AreaPrincipal/SeoURL')
                .expand('AuxCategoria, AuxIdioma, AuxSociedade, AreaPrincipal')
                .orderBy('Data', false)
                .top(3)
                .get().then((results) => {
                    this.$set(this, 'noticiasPublicacoes', results);
                })
        },
        getNoticiaUrl(item) {

            var area = (!this.isEnglish && (item.AreaPrincipal || {}).SeoURL ? (item.AreaPrincipal || {}).SeoURL : (item.AreaPrincipal || {}).SeoURLen) || 'institucional';
            const idioma = this.isEnglish ? '/en-US' : '';
            if (_spPageContextInfo.webAbsoluteUrl.includes('/pi')) {
                return `${(_spPageContextInfo.webAbsoluteUrl).split('/pi')[0]}${idioma}/pi/conteudo/${area}/${this.isEnglish ? (item.SeoURLen ? item.SeoURLen : item.SeoURL) : item.SeoURL}`;
            } else {
                return `${_spPageContextInfo.webAbsoluteUrl}${idioma}/conteudo/${area}/${this.isEnglish ? (item.SeoURLen ? item.SeoURLen : item.SeoURL) : item.SeoURL}`;
            }
        },
        applyEvents: function () {

        },
        threatError: function (msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        },
        getCustomData(data) {
            if (!this.isEnglish)
                return moment(data).format('DD . MMM . YYYY');
            else
                return moment(data).lang("en-us").format('MMM . DD . YYYY');
        },
        getCustomData2(data) {
            var dia = moment(data).format('DD'),
                mes = moment(data).format('MMM'),
                mesEN = moment(data).lang("en-us").format('MMM');
            if (!this.isEnglish)
                return `<strong>${dia}</strong>${mes}`
            else
                return `<span class="font-weight-bold">${mesEN}</span><br>${dia}`
        },
        onClickCategoria(id) {
            this.$emit('change', id);
            this.loadResults(id)
        }
    },
    data: function () {
        var listName = 'Noticias'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/' + listName + '',
            noticiasPublicacoes: [],
            noticiaDestaque: null,
            destaqueId: 0
        }
    }
})
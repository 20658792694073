require('../../mixins/translate');

$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('links-rapidos', {
    template: `
    <element>        
        <div class="container polygon before-left">
            <div class="row">
                <article id="busca-mobile" class="text-center col-12 col-sm-6">
                   <busca-home />
                </article>
                <article id="links-rapidos" class="col-12 col-sm-6">
                    <div class="row">
                        <a :href="formatUrl('advogados')" :title="isEnglish ? 'Professionals' : 'Profissionais'" class="p-3 col-6 text-center bg-primary text-white text-uppercase">
                            <span>{{ isEnglish ? 'Professionals' : 'Profissionais' }}</span>
                        </a>
                        <a v-if="!isPI" :href="formatUrl('areas-atuacao')" :title="isEnglish ? 'Practices' : 'Áreas de Atuação'" class="p-3 col-6 text-center bg-cinza text-white text-uppercase center-button">
                            <span v-if="!isPI">{{ isEnglish ? 'Practices' : 'Áreas de Atuação' }}</span>
                        </a>
                        <a v-else class="p-3 col-6 text-center bg-cinza text-white text-uppercase center-button"></a>
                    </div>
                </article>
            </div>
        </div>
    </element>
    `,
    created: function () {
        this.loadResults()
    },
    mixins: [pnpMixin, translateMixin],
    methods: {
        threatError: function (msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        },
    },
    data: function () {
        var listName = 'ESG'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/' + listName + '',
            compromissos: {},
            isPI: SociedadeValue == 'PI',
            isMobile: window.innerWidth <= 996
        }
    }
})

var app = new Vue({
    el: '#links-rapidos',
    template: '<links-rapidos/>'
})
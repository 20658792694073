$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

require('../../mixins/translate');

Vue.component(`img-responsabilidade-social`, {
    mixins: [translateMixin],
    template: `
        <element v-if="item">
            <img :src="isEnglish ? (item.LinkImagem2 ? item.LinkImagem2 : item.LinkImagem) : item.LinkImagem" :alt="item.Title" class="img-fluid w-100 mb-2"/>
        </element>
    `,
    data() {
        var listName = 'HomeConteudo';
        return {
            listName: listName,
            listURL: `${_spPageContextInfo.siteServerRelativeUrl}lists/${listName}`,
            item: {}
        }
    },
    methods: {
        getPicture(){
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listURL).items
                .select('*')
                .filter(`LocalHome eq 'Nos e a Sociedade'`)
                .top(1)
                .get().then((result) => {
                    Vue.set(this, 'item', result[0]);
                })
        }
    },
    created() {
        this.getPicture()
    },
});
$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('component-premios-reconhecimentos',{
    mixins: [pnpMixin, translateMixin],
    template:`
        <element>
            <div class="slider-premios">
                <div class="premio text-right" v-for="item in premiosReconhecimentos">
                    <a :href="formatUrl('premios-reconhecimentos?rec=' + item.SeoURL)" :title="!isEnglish ? 'Veja este Reconhecimento' : 'See this acknowledgment'">
                        <img :src="item.LinkImagem" :alt="!isEnglish ? item.Title : item.TitleEn" class="d-block float-right w-100" />
                    </a>
                </div>
            </div>
        </element>
    `,
    created: function(){
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this,'erro ao obter informações da lista '+this.listName+''))
            .then(this.applyEvents)
    },
    methods: {
        getData: function(){
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                .filter(`ExibirHome eq 1 and ExibirHomeSociedade/Title eq '${SociedadeValue}'`)
                .select('*, AuxSociedade/Title, ExibirHomeSociedade/Title')
                .expand('AuxSociedade, ExibirHomeSociedade')
                .orderBy('Ordem',true)
                //.top()
                .get()
        },
        applyGetData: function(data){
            this.premiosReconhecimentos = data;
        },
        applyEvents: function(){
            //slider o que fazemos
            Vue.nextTick(function(){
                $('.slider-premios').slick({
                    dots: true,
                    arrows: false,
                    autoplay: false,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                                dots: true,
                                autoplay: true,
                                autoplaySpeed: 2000
                            }
                        }
                    ]
                })
            })
        },
        threatError: function(msg,data){
            console.error('error - ' + msg + ' - ' + data);
        }
    },
    data: function() {
        var listName = 'Reconhecimentos'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/'+ listName+'',
            premiosReconhecimentos: []
        }
    }
})
import Slick from 'vue-slick';

$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('categorias-noticias', {
    template: `
    <div class="container content-full">
        <div class="row">
            <div class="col-12 offset-sm-6 col-sm-6 wow bounceInDown" data-wow-delay=".5s">
            <slick class="categorias-midia"
            ref="slick"
            v-if="categoriasNoticias"
            :options="slickOptions">
                <div class="categoria" v-for="(item, i) in categoriasNoticias">
                    <a  href="javascript:" 
                        :class="selectedCat == item.ID || (!selectedCat && i == 0) ? 'active' : ''" 
                        @click="onClickCategoria(item.ID)" 
                        title="" 
                        class="text-center text-uppercase d-block p-3 border border-left-0 border-bottom-0"> 
                        {{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}
                    </a>
                </div>
          </slick>
            </div>
        </div>
    </div>
    `,
    mixins: [translateMixin],
    data: function () {
        var listName = 'AuxCategoriaNoticia'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/' + listName + '',
            categoriasNoticias: null,
            selectedCat: 0,
            slickOptions: {
                slidesToShow: 6,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                variableWidth: false,
                infinite: false,
                swipe: false,
                responsive: [
                    {
                        breakpoint: 996,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            swipe: true
                        }
                    }
                ]
                // Any other options that can be got from plugin documentation
            },
        }
    },
    components: { Slick },
    created: function () {
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
            .then(this.applyEvents)
    },
    updated: function () {
        this.applyEvents()
    },
    methods: {
        getData: function () {
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                //.filter()
                .select('*')
                //.expand('AuxCategoria')
                //.orderBy('field',true)
                //.top()
                .get()
        },
        applyGetData: function (data) {
            this.categoriasNoticias = data;
        },
        applyEvents: function () {

        },
        threatError: function (msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        },
        removeAccents(strAccents) {
            var strAccents = strAccents.split('');
            var strAccentsOut = new Array();
            var strAccentsLen = strAccents.length;
            var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
            var accentsOut = ['A', 'A', 'A', 'A', 'A', 'A', 'a', 'a', 'a', 'a', 'a', 'a', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'o', 'o', 'o', 'o', 'o', 'o', 'E', 'E', 'E', 'E', 'e', 'e', 'e', 'e', 'e', 'C', 'c', 'D', 'I', 'I', 'I', 'I', 'i', 'i', 'i', 'i', 'U', 'U', 'U', 'U', 'u', 'u', 'u', 'u', 'N', 'n', 'S', 's', 'Y', 'y', 'y', 'Z', 'z'];
            for (var y = 0; y < strAccentsLen; y++) {
                if (accents.indexOf(strAccents[y]) != -1) {
                    strAccentsOut[y] = accentsOut[accents.indexOf(strAccents[y])];
                }
                else
                    strAccentsOut[y] = strAccents[y];
            }
            strAccentsOut = strAccentsOut.join('');
            return strAccentsOut.toLowerCase();
        },
        onClickCategoria(id) {
            this.$emit('change', id);
            this.selectedCat = id;
        }
    }
})

if (document.querySelector('#categorias-noticias')) {
    new Vue({
        el: '#categorias-noticias',
        template: '<categorias-noticias/>'
    })
}
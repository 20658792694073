$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('banner-home',{
    template:`
    <element>
        <!--<a href="javascript:" id="bannerPrev" class="bt-prev-slick slick-arrow"><i class="fa fa-chevron-left"></i></a>
        <a href="javascript:" id="bannerNext" class="bt-next-slick slick-arrow"><i class="fa fa-chevron-right"></i></a>-->
        <div class="banner-home">
            <div class="banner" v-for="item in bannerHome">
                <div class="img-banner" :style="getBackgroundImage(item)">
                    <a :href="getURL(!isEnglish ? item.URL : (item.URLEn || item.URL))" :title="isEnglish ? item.TitleEN : item.Title" :target="item.NovaAba ? 'blank' : ''">
                        <div class="container polygon after-left white">
                            <div :class="'text-banner ' + alinhamentoConteudo(item.AlinhamentoConteudo)">
                                <!-- icone / logo -->
                                <template v-if="isEnglish">
                                    <div class="w-auto"><img v-show="item.LinkImagem3" :src="item.LinkImagem3" :alt="item.TitleEN" /></div>
                                </template>
                                <template v-else>
                                    <div class="w-auto"><img v-show="item.LinkImagem2" :src="item.LinkImagem2" :alt="item.Title"/></div>
                                </template>
                                <!-- /icone / logo -->
                                <template v-if="item.ExibirTitulo == 1">
                                    <h1 class="text-uppercase mt-2 mb-2 font-weight-bold" v-bind:style="estilizarTitulo(item)">{{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}</h1>
                                    <p v-show="item.Descricao" v-bind:style="estilizarSubTitulo(item)">{{ !isEnglish ? item.Descricao : (item.DescricaoEN || item.Descricao) }}</p>
                                </template>
                                <div class="btn-banner-home" v-if="isEnglish ? item.URLEn : item.URL">
                                    <div v-if="getURL(!isEnglish ? item.URL : (item.URLEn || item.URL))" :title="!isEnglish ? item.TituloURL : item.TituloURLEN" class="d-sm-inline bg-primary text-white mx-auto text-uppercase font-weight-bold p-3 mt-2 link-banner-desktop" :style="estilizarBotao(item)">
                                        {{ isEnglish ? item.TituloURLEN : item.TituloURL }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <!-- botao mobile 
                    <a v-if="getURL(!isEnglish ? item.URL : (item.URLEn || item.URL))" :href="getURL(!isEnglish ? item.URL : (item.URLEn || item.URL))" :title="item.Title" class="d-block d-sm-none border text-center p-2 link-banner">
                        <i class="fa fa-link"></i>
                    </a>
                    /botao mobile -->
                </div>
            </div>
        </div>
    </element>
    `,
    mixins: [translateMixin],
    created: function(){
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this,'erro ao obter informações da lista '+this.listName+''))
            .then(this.applyEvents)
    },
    methods: {
        getData: function(){
            var today = moment().format('YYYY-MM-DD[T]03:00:00[Z]');
            console.log(today)

            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                .filter(`AuxSociedade eq '${SociedadeValue}' and Exibir eq 1 and (DataPublicacao le datetime'${today}' and DataExpiracao ge datetime'${today}') and AuxIdioma/Title eq '${CurrentLanguageLCID}'`)
                .select('*, AuxIdioma/Title, CorTitulo/Cor, CorSubTitulo/Cor, CorBotao/Cor, CorTextoBotao/Cor')
                .expand('AuxIdioma, CorTitulo, CorSubTitulo, CorBotao, CorTextoBotao')
                .orderBy('Ordem',true)
                //.top()
                .get()
        },
        applyGetData: function(data){
            this.bannerHome = data;
        },
        isSafari() {
            var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (isSafari && iOS) {
                $('#banner').css({
                    height: 'calc(100vh - 280px)'
                });
            } else if(isSafari) {
                //
            }
        },
        applyEvents: function(){
                //banner + busca height automatico
                var heightDynamic = window.innerHeight+'px';
                var userAgent = window.navigator.userAgent;

                if(window.innerWidth <= 992){
                    heightDynamic = (window.innerHeight - 90)+'px';
                    this.isSafari();
                }

                $('#banner-height').height(heightDynamic);
                // banner home - slick slider
                Vue.nextTick(function(){
                    $('.banner-home').slick({
                        arrows: false,
                        dots: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        swipe:false,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    dots: true,
                                    arrows: false,
                                    swipe: true
                                }
                            }
                        ]
                    });
                    $('.banner[bg-image]').each(function(){
                        var imgURL = $(this).attr('bg-image');
                        $(this).css('style',`${imgURL}`)
                    })
                })
        },
        threatError: function(msg,data){
            console.error('error - ' + msg + ' - ' + data);
        },
        getBackgroundImage(image){
            if(this.isMobile){
                return `background-image:url('${image.LinkImagem4}')`;
            }else {
                return `background-image:url('${image.LinkImagem}')`;
            }
        },
        getURL(URL){
            if(URL){
                if(URL == '#'){
                    return false
                }else {
                    return URL
                }
            }
        },
        alinhamentoConteudo(alinhamento){
            if(alinhamento == 'Esquerda'){
                return 'text-left'
            }else if(alinhamento == 'Centro'){
                return 'text-center'
            }else{
                return 'text-right'
            }
        },
        estilizarTitulo(item) {
            var fonteTitulo = '';
            var corTitulo = '';

            if (item.TamanhoFonteTitulo)
                fonteTitulo = item.TamanhoFonteTitulo
            if (item.CorTitulo.Cor)
                corTitulo = item.CorTitulo.Cor

            return `font-size: ${fonteTitulo};` + `color: ${corTitulo};`
        },
        estilizarSubTitulo(item) {
            var fonteSubtitulo = '';
            var corSubtitulo = '';

            if (item.TamanhoFonteTexto)
                fonteSubtitulo = item.TamanhoFonteTexto
            if(item.CorSubTitulo.Cor)
                corSubtitulo = item.CorSubTitulo.Cor
            
            return `font-size: ${fonteSubtitulo};` + `color: ${corSubtitulo};`
        },
        estilizarBotao(item) {
            var corBotaoBG = '';
            var corBotaoTxt = '';

            if (item.CorBotao) {
                corBotaoBG = item.CorBotao.Cor;
            }
            if (item.CorTextoBotao) {
                corBotaoTxt = item.CorTextoBotao.Cor;
            }
            return `background-color: ${corBotaoBG}!important;` + `color: ${corBotaoTxt}!important;`
        }
    },
    data: function() {
        var listName = 'Banner'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + 'lists/' + listName +'',
            bannerHome: [],
            isMobile: window.innerWidth <= 996
        }
    }
})

var app = new Vue({
    el: '#banner-home',
    template: '<banner-home/>'
})
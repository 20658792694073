require('../../mixins/pnp-mixin'); // pnp mixin
require('../../mixins/translate');
require('./videos'); // vídeos home
require('./responsabilidade-social'); // responsabilidade social
require('./premios-reconhecimentos'); // premios e reconhecimentos
require('./banner-home'); // banner home
require('./links-rapidos'); // links rápidos (advogados e práticas)
require('../categorias-noticias'); // categorias noticias
require('./noticias-publicacoes'); // noticias e publicações
require('./busca-home'); // busca home
require('./img-responsabilidade-social'); // img responsabilidade social

$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('home-component', {
    mixins: [translateMixin, pnpMixin],
    template: `
        <element>
            <section id="o-bma">
            <img v-if="showScriptMonitoramentoLaw()" src="https://d21y75miwcfqoq.cloudfront.net/1613779a"/>
            <img v-if="checkScriptMonitoringPi()" src="https://d21y75miwcfqoq.cloudfront.net/002acd07"/>
                <img src="../../style library/images/img-quem-somos.png" class="d-block d-sm-none img-fluid mx-auto" alt="O BMA - Conheça Nosso Escritório"/>
                <div class="container polygon before-left after-right">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <img src="../../style library/images/img-quem-somos.png" class="d-none d-sm-block img-fluid mx-auto" alt="O BMA - Conheça Nosso Escritório"/>
                        </div>
                        <div class="col-12 col-sm-6 custom-subtitle" v-for="item in list" v-if="item.LocalHome == 'BMA'">
                            <h2 class="text-primary font-weight-bold">
                                <span>{{ !isEnglish ? item.Title : item.TitleEn }}</span>
                            </h2>
                            <h3 class="text-padrao text-uppercase sub-title">
                                <span>{{ !isEnglish ? item.SubTitile : item.SubTitileEn }}</span>
                            </h3>
                            <p class="mb-3">
                                <span>{{ !isEnglish ? item.Conteudo : (item.ConteudoEn || item.Conteudo) }}</span>
                            </p>
                            <a :href="!isEnglish ? item.URL : (item.URLEN ? item.URLEN : item.URLEN)" :title="!isEnglish ? item.TitleBotao : item.TitleBotaoEn" class="saiba-mais p-3 mb-4 font-weight-bold text-uppercase d-block d-sm-block text-center border border-primary text-primary">
                                <span>{{ !isEnglish ? item.TitleBotao : item.TitleBotaoEn }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="bma-midia" class="pb-0" v-for="item in list" v-if="item.LocalHome == 'Midia'">
                <div class="container polygon before-right">
                    <h2 class="text-uppercase text-primary font-weight-bold mb-2">
                        <span>{{ !isEnglish ? 'Destaques' : 'Spotlight' }}</span>
                    </h2>
                    <h3 class="sub-title text-uppercase mb-1">
                        <span>{{ !isEnglish ? item.SubTitile : item.SubTitileEn }}</span>
                    </h3>
                </div>
                <component-noticias-publicacoes :dados-btn="item"></component-noticias-publicacoes>
            </section>
            <section id="videos-home">
                <div class="pb-1 pt-2">
                    <div class="container two-polygons before-left-bottom after-right-bottom">
                        <div class="row">
                            <div class="bg-light pt-4 col-12 col-sm-4 custom-subtitle" v-for="item in list" v-if="item.LocalHome == 'Videos'">
                                <h2 class="text-uppercase text-primary font-weight-bold">
                                    <span>{{ !isEnglish ? item.Title : item.TitleEn }}</span>
                                </h2>
                                <h3 class="text-padrao sub-title text-uppercase" v-if="(isEnglish && item.SubTitleEn) || (!isEnglish && item.SubTitle)">
                                    <span>{{ !isEnglish ? item.SubTitile : item.SubTitileEn }}</span>
                                </h3>
                                <p class="d-none d-sm-block">
                                    <span>{{ !isEnglish ? item.Conteudo : item.ConteudoEn }}</span>
                                </p>
                                <a :href="!isEnglish ? item.URL : (item.URLEN ? item.URLEN : item.URLEN)" title="Nossos Vídeos" class="d-none d-sm-block position-relative mt-3 font-weight-bold text-uppercase p-2 border border-secondary text-center mx-auto">
                                    <span>{{ isEnglish ? 'Find out more' : 'Ver mais' }}</span>
                                </a>
                            </div>
                            <div class="col-12 col-sm-8 content-full">
                                <component-videos-home></component-videos-home>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-for="item in list" v-if="item.LocalHome == 'Videos'">
                    <a :href="!isEnglish ? item.URL : (item.URLEN ? item.URLEN : item.URLEN)" :title="!isEnglish ? item.TitleBotao : item.TitleBotaoEn" class="position-relative mt-5 font-weight-bold text-uppercase d-block d-sm-none p-2 border border-secondary text-center mx-auto">
                         <span>{{ !isEnglish ? item.TitleBotao : item.TitleBotaoEn }}</span>
                    </a>
                </div>
            </section>

            <section id="responsabilidade-social" v-for="item in list" v-if="item.LocalHome == 'Nos e a Sociedade'">
                <div class="container polygon before-left custom-subtitle">
                    <h2 class="text-uppercase font-weight-bold text-primary">
                        <span>{{ !isEnglish ? item.Title : item.TitleEn }}</span>
                    </h2>
                    <h3 class="sub-title text-uppercase mb-1">
                        <span>{{ !isEnglish ? item.SubTitile : item.SubTitileEn }}</span>
                    </h3>
                </div>
                <div class="container content-full">
                    <div class="row">
                        <div class="col-12"></div>
                            <div class="img-responsabilidade mb-1 polygon before-left">
                                <img-responsabilidade-social />
                                <div class="flipbook">
                                    <div>
                                        <a :href="!isEnglish ? item.URL : (item.URLEN ? item.URLEN : item.URLEN)" target="blank" class="d-block p-3 text-center text-uppercase border border-primary text-primary mt-4 mb-4 font-weight-bold ver-todos">{{ !isEnglish ? item.TextoFlipbook : item.TextoFlipbookEN }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-9">
                            <component-responsabilidade-social></component-responsabilidade-social>
                        </div>
                    </div>
                </div>
            </section>
            <section id="o-que-dizem" v-for="item in list" v-if="item.LocalHome == 'Prêmios'">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-5 titles text-right custom-subtitle">
                            <h2 class="text-uppercase text-primary font-weight-bold">
                                <span>{{ !isEnglish ? item.Title : item.TitleEn }}</span>
                            </h2>
                            <h3 class="sub-title text-uppercase mb-1 d-inline-block">
                                <span>{{ !isEnglish ? item.SubTitile : item.SubTitileEn }}</span>
                            </h3>
                        </div>
                        <div class="col-12 col-sm-7 float-right align-items-center">
                            <component-premios-reconhecimentos></component-premios-reconhecimentos>
                        </div>
                    </div>
                </div>
            </section>
        </element>
    `,
    created: function () {
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
            .then(this.applyEvents)
    },
    methods: {
        showScriptMonitoramentoLaw() {
            return (window.location.href.indexOf("law") !== -1)
        },
        checkScriptMonitoringPi() {
            return (window.location.href.indexOf("pi") !== -1)
        },
        getData: function () {
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                //.filter()
                .select('*')
                //.expand()
                //.orderBy('field',true)
                //.top()
                .get()
        },
        applyGetData: function (data) {
            this.list = data;
        },
        applyEvents: function () {

            ////////////////////////////////////////////
            //  MASTER - UNIFICANDO COMPONENTS E JS
            ////////////////////////////////////////////
            // ocultando ribbon na home

            if (_spPageContextInfo.serverRequestPath.match(/default/i)) {
                $('#s4-workspace').removeAttr('style');
                document.getElementById('ms-designer-ribbon').style.display = 'none';
            }

            //scroll bg header
            var $document = $('#s4-workspace'),
                $element = $('.categorias-midia'),
                className = 'category-fixed',
                isMobile = window.innerWidth <= 768;

            //scroll para o header
            $document.scroll(function () {
                if (!isMobile) {
                    if ($document.scrollTop() >= 1826.3636474609375 && $document.scrollTop() <= 2686.363525390625) {
                        // user scrolled 50 pixels or more;
                        // do stuff
                        $('.categorias-midia').addClass(className);
                    } else {
                        $('.categorias-midia').removeClass(className);
                    }
                } else {
                    if ($document.scrollTop() >= 1974 && $document.scrollTop() <= 2880) {
                        // user scrolled 50 pixels or more;
                        // do stuff
                        $('.categorias-midia').addClass(className);
                    } else {
                        $('.categorias-midia').removeClass(className);
                    }
                }
            });

        },
        threatError: function (msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        }
    },
    data: function () {
        var listName = 'HomeConteudo'
        return {
            listName: listName,
            listUrl: `${_spPageContextInfo.siteServerRelativeUrl}/lists/HomeConteudo`,
            list: []
        }
    }
})

var app = new Vue({
    el: '#home-component',
    template: '<home-component/>'
})
